<template>
  <b-row style="background-color: #F5F7FA; padding-bottom: 50px;" class="d-flex justify-content-center content">
    <b-col style="max-width: 1200px; min-height: 800px;">
      <b-row>

        <b-col lg="12">
          <h1 v-if="vendor.vendor_id" style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">{{ staticDisplayName }}</h1>
          <h1 v-else style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">New Vendor</h1>
        </b-col>

        <b-col lg="7" class="mb-3">
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body>

              <!-- ACTIVE TOGGLE -->
              <toggle-button
                @change="toggleActive"
                :value="vendor.active"
                :sync="true"
                :color="{
                  checked: '#28a745',
                  unchecked: '#6c757d',
                  disabled: '#343a40'
                }"
                :labels="{ checked: 'Active', unchecked: 'Inactive' }"
                :width="85"
                :height="30"
                :font-size="12"
                class="float-right"
              />

              <!-- LOGO UPLOAD -->
              <label for="logo">Logo:</label>
              <div style="width:275px; margin-left:0px;" class="text-center img-thumbnail">
                <b-img
                  fluid
                  v-if="vendor.logo"
                  :src="vendor.logo"
                  class="p-1 mb-2"
                />
                <b-img
                  fluid
                  v-else
                  :src="logoPlaceholder"
                  class="p-1 mb-2"
                />
                <b-form-file hidden v-model="newLogo" ref="logoSelector" class="" plain></b-form-file>
                <button @click.prevent="simulateLogoSelectClick" v-show="vendor.vendor_id && vendor.logo && !processingLogoUpload" id="" class="btn btn-light btn-block" style="margin-top: 0px;">Replace Default Logo</button>
                <button @click.prevent="simulateLogoSelectClick" v-show="vendor.vendor_id && !vendor.logo && !processingLogoUpload" id="" class="btn btn-light btn-block" style="margin-top: 0px;">Upload Default Logo</button>
                <button disabled v-show="processingLogoUpload" id="" class="btn btn-light btn-block" style="margin-top: 0px;"><b-spinner class="mr-2" small></b-spinner> Uploading...</button>
                <p v-show="!vendor.vendor_id" class="small mb-1">Please save this vendor before adding a logo.</p>
              </div>

              <!-- PDF UPLOAD -->
              <label class="mt-4" for="logo">PDF Sell Sheet:</label>
              <div style="width:275px; margin-left:0px;" class="text-center img-thumbnail">
                <b-img
                  fluid
                  v-show="!vendor.pdf"
                  :src="pdfPlaceholder"
                  class="p-1 mb-2"
                />

                <button v-show="vendor.pdf" @click="openPdf(vendor.pdf)" class="btn btn-secondary btn-block">
                  <b-icon-box-arrow-up-right style="margin-right: 2px;"></b-icon-box-arrow-up-right>
                  Preview
                </button>

                <b-form-file hidden v-model="newPdf" ref="pdfSelector" class="" plain></b-form-file>
                <button @click.prevent="simulatePdfSelectClick" v-show="vendor.vendor_id && vendor.pdf && !processingPdfUpload" id="" class="btn btn-light btn-block" style="margin-top: 0px;">Replace Default PDF</button>
                <button @click.prevent="simulatePdfSelectClick" v-show="vendor.vendor_id && !vendor.pdf && !processingPdfUpload" id="" class="btn btn-light btn-block" style="margin-top: 0px;">Upload Default PDF</button>
                <button disabled v-show="processingPdfUpload" id="" class="btn btn-light btn-block" style="margin-top: 0px;"><b-spinner class="mr-2" small></b-spinner> Uploading...</button>
                <p v-show="!vendor.vendor_id" class="small mb-1">Please save this vendor before adding a PDF.</p>
              </div>

              <b-form @submit.prevent="saveVendor" @reset.prevent="onReset" class="mt-4">
              <!-- DISPLAY NAME -->
                <b-form-group
                  id="display-name-group"
                  label="Display Name:"
                  label-for="display-name"
                  description=""
                >
                  <p v-show="!validation.display_name" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a display name longer than 2 characters.
                  </p>
                  <b-form-input
                    id="display-name"
                    :class="{ 'error' : !validation.display_name}"
                    style="max-width: 350px;"
                    v-model="vendor.display_name"
                    placeholder=""
                    trim
                  ></b-form-input>
                </b-form-group>

              <!-- SHORT NAME -->
                <b-form-group
                  id="short-name-group"
                  label="Short Name:"
                  label-for="short-name"
                  description=""
                >
                  <p v-show="!validation.short_name" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a short name longer than 1 character.
                  </p>
                  <b-form-input
                    id="short_name"
                    :class="{ 'error' : !validation.short_name }"
                    style="max-width: 350px;"
                    :disabled="vendor.vendor_id != null"
                    v-model="vendor.short_name"
                    placeholder="example: jd_power"
                    trim
                  ></b-form-input>
                </b-form-group>

              <!-- DESCRIPTION #1 -->
                <b-form-group
                  id="description-1-group"
                  label="Description #1:"
                  label-for="description-1"
                  description=""
                >
                  <p v-show="!validation.description_1" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a vendor description longer than 10 characters.
                  </p>
                  <b-form-textarea
                    id="description-1"
                    :class="{ 'error' : !validation.description_1 }"
                    v-model="vendor.description_1"
                    placeholder=""
                    trim
                    rows="6"
                  ></b-form-textarea>
                </b-form-group>

              <!-- DESCRIPTION #2 -->
                <b-form-group
                  id="description-2-group"
                  label="Description #2:"
                  label-for="description-2"
                  description=""
                >
                  <p v-show="!validation.description_2" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a vendor description longer than 10 characters.
                  </p>
                  <b-form-textarea
                    id="description-2"
                    :class="{ 'error' : !validation.description_2 }"
                    v-model="vendor.description_2"
                    placeholder=""
                    trim
                    rows="6"
                  ></b-form-textarea>
                </b-form-group>

                <!-- VIDEO LINK -->
                <b-form-group
                  id="video-group"
                  label="Video:"
                  label-for="video"
                  description=""
                >
                  <p v-show="!validation.video" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter a path to an mp4 video file.
                  </p>
                  <b-form-input
                    id="video-link"
                    :class="{ 'error' : !validation.video }"
                    v-model="vendor.video"
                    placeholder="full path to live mp4 video file"
                    trim
                  ></b-form-input>
                </b-form-group>

                <!-- WEBSITE -->
                <b-form-group
                  id="website-group"
                  label="Website:"
                  label-for="website"
                  description=""
                >
                  <p v-show="!validation.website" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter website url.
                  </p>
                  <b-form-input
                    id="website"
                    :class="{ 'error' : !validation.website }"
                    v-model="vendor.website"
                    placeholder=""
                    trim
                  ></b-form-input>
                </b-form-group>

                <b-button v-show="!processingMain" type="submit" class="btn btn-primary btn-dash-submit float-right">Save</b-button>
                <b-button v-show="processingMain" disabled type="submit" class="btn btn-primary btn-dash-submit float-right"><b-spinner class="mr-2" small></b-spinner> Saving...</b-button>
                <b-button @click="cancel" class="btn btn-light float-right mr-3">Cancel</b-button>

              </b-form>

            </b-card-body>
          </b-card>
        </b-col>

        <b-col lg="5">
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body>

              <div v-show="!editingCustomContent">
                <h2 style="font-size: 18px; margin-bottom: 10px; font-weight: 600;">Custom Content</h2>
                <p class="small">Custom content overrides default content for specific categories.</p>
                <b-icon-plus @click="newCustomContent()" v-if="vendorCategories.length > 1 || customContent.length != 0" class="plus-icon float-right" style="margin-top: -70px;"></b-icon-plus>
                <div v-if="!vendor.vendor_id">
                  <p class="small">Looking to add some custom content? Two things need to happpen before that's possible:</p>
                  <ul class="small">
                    <li>Vendor needs to be saved.</li>
                    <li>Vendor needs to be associated with more than one category.</li>
                  </ul>
                </div>

                <b-alert show v-if="vendorCategories.length < 2 && customContent.length == 0" style="margin-bottom: 0px;">
                  <b-icon-info-circle-fill style="height: 17px; width: 17px; margin-top: 5px; margin-right: 4px;"></b-icon-info-circle-fill>
                    To add custom content, you'll need to add <strong>{{ vendor.display_name }}</strong> to more than one category.
                </b-alert>

                <b-alert show v-if="vendorCategories.length > 1 && customContent.length == 0" style="margin-bottom: 0px;">
                  <b-icon-info-circle-fill style="height: 17px; width: 17px; margin-top: 5px; margin-right: 4px;"></b-icon-info-circle-fill>
                  No custom content found.
                </b-alert>

                <div v-if="customContent.length != 0">
                  <b-card style="margin-top: 25px;" v-for="(item, index) in customContent" :key="index">
                    <span @click="toggleSnowmanMenu(index)" style="border-radius: 15px; position: absolute; right: 20px; width: 30px; height: 30px;" class="snowman-container">
                      <b-icon-three-dots-vertical v-show="!item.snowmanMenuActive" style="position: absolute; left: 5px; top: 5px; width: 20px; height: 20px;" class="snowman"></b-icon-three-dots-vertical>
                      <b-icon-three-dots v-show="item.snowmanMenuActive" style="position: absolute; left: 5px; top: 5px; width: 20px; height: 20px;" class="snowman"></b-icon-three-dots>
                    </span>
                    <div v-if="item.snowmanMenuActive" class="snowman-flyout-menu" style="position: absolute; width: 100px; height: auto; right: 20px; top: 55px; border: 1px solid #dee2e6; border-radius: 3px; background-color: rgb(245, 247, 250); color: rgb(73, 80, 87); padding: 10px; text-align: left;">
                      <a @click.prevent="editCustomContent(index)" href="#" style="margin-top: 0px; float: left;">Edit</a>
                      <a @click.prevent="deleteCustomContent(index)" href="#" style="margin-top: 0px;  float: left;">Delete</a>
                    </div>
                    <b-badge class="mr-2" variant="primary">{{ item.category_display_name|capitalizeAll }}</b-badge>
                    <b-badge variant="success">{{ item.content_type.replace("_", " #")|capitalizeAll }}</b-badge>
                    <hr>
                    <span v-if="item.content_type == 'pdf'">
                      <p style="margin-bottom: 0px;">File name: <a href="#" @click.prevent="openPdf(item.full_pdf_url)" style="margin-bottom: 0px; padding-left: 3px;">{{ item.content }}</a></p>
                    </span>
                    <span v-if="item.content_type == 'logo'">
                      <b-img
                        fluid
                        :src="item.full_logo_url"
                        class="p-1 mb-2"
                        width="275px"
                      />
                    </span>
                    <p v-if="item.content_type != 'pdf' && item.content_type != 'logo'" style="margin-bottom: 0px; color: #495057;">{{ item.content }}</p>
                  </b-card>
                </div>
              </div>

              <div v-if="editingCustomContent">
                <h2 v-if="customContentFields.custom_content_id" style="font-size: 18px; margin-bottom: 15px; font-weight: 600;">Edit Custom Content</h2>
                <h2 v-else style="font-size: 18px; margin-bottom: 15px; font-weight: 600;">New Custom Content</h2>

                <b-form-select v-model="customContentFields.category_id" :options="customContentCategoryOptions" class="mb-3">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>-- Please select a category --</b-form-select-option>
                  </template>
                </b-form-select>

                <b-form-select v-show="customContentFields.category_id" v-model="customContentFields.content_type" :options="customContentTypeOptions" class="mb-3">
                  <template v-slot:first>
                    <b-form-select-option :value="null" disabled>-- Please select a content type --</b-form-select-option>
                  </template>
                </b-form-select>

                <!-- CUSTOM CONTENT PDF -->
                <b-form-file v-model="customContentFields.pdf" v-if="customContentFields.content_type == 'pdf'" ref="cc_pdf" class="mb-1 p-1 b-1" plain></b-form-file>

                <!-- CUSTOM CONTENT LOGO -->
                <b-form-file v-model="customContentFields.logo" v-if="customContentFields.content_type == 'logo'" ref="cc_logo" class="mb-1 p-1 b-1" plain></b-form-file>

                <!-- CUSTOM CONTENT DESCRIPTION #1 -->
                <b-form-group
                  v-if="customContentFields.content_type == 'description_1'"
                  id="cc-description-1-group"
                  label="Custom Content Description #1:"
                  label-for="cc-description-1"
                  description=""
                >
                  <b-form-textarea
                    id="cc-description-1"
                    v-model="customContentFields.description_1"
                    placeholder=""
                    trim
                    rows="6"
                  ></b-form-textarea>
                </b-form-group>

                <!-- CUSTOM CONTENT DESCRIPTION #2 -->
                <b-form-group
                  v-if="customContentFields.content_type == 'description_2'"
                  id="cc-description-2-group"
                  label="Custom Content Description #2:"
                  label-for="cc-description-2"
                  description=""
                >
                  <b-form-textarea
                    id="cc-description-2"
                    v-model="customContentFields.description_2"
                    placeholder=""
                    trim
                    rows="6"
                  ></b-form-textarea>
                </b-form-group>

                <!-- CUSTOM CONTENT VIDEO LINK -->
                <b-form-group
                  v-if="customContentFields.content_type == 'video'"
                  id="cc-video-group"
                  label="Video Link:"
                  label-for="cc-video"
                  description=""
                >
                  <b-form-input
                    id="cc-video-link"
                    v-model="customContentFields.video"
                    placeholder="full path to live mp4 video file"
                    trim
                  ></b-form-input>
                </b-form-group>

                <!-- CUSTOM CONTENT WEBSITE -->
                <b-form-group
                  v-if="customContentFields.content_type == 'website'"
                  id="cc-website-group"
                  label="Custom Content Website:"
                  label-for="cc-website"
                  description=""
                >
                  <b-form-input
                    id="cc-website"
                    v-model="customContentFields.website"
                    placeholder=""
                    trim
                  ></b-form-input>
                </b-form-group>
                
                <b-button v-show="!processingCustomContent && customContentFields.content_type" @click="saveCustomContent" class="btn btn-primary btn-dash-submit float-right ml-3">Save</b-button>
                <b-button v-show="processingCustomContent && customContentFields.content_type" disabled class="btn btn-primary btn-dash-submit float-right ml-3"><b-spinner class="mr-2" small></b-spinner> Saving...</b-button>
                <b-button @click="resetCustomContent" class="btn btn-light float-right">Cancel</b-button>
              
              </div>

            </b-card-body>
          </b-card>

        </b-col>

      </b-row>      
    </b-col>
  </b-row>

</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button'

export default {
  name: 'edit-vendor',
  components: {
    ToggleButton,
  },

  created () {
    this.getAllCategories();
    if(this.$route.params.vendorId){
      this.getVendor();
      this.getVendorCategories();
      this.getCustomContent();
    }
  },

  metaInfo() {
    return {
      title: this.vendor.display_name ? 'Edit' + ' ' + this.vendor.display_name : 'New Vendor'
    }
  },

  watch: {
    'newLogo' () {
      this.saveLogo();
    },
    'newPdf' () {
      this.savePdf();
    }
  },

  data() {
    return {
      processingMain: false,
      processingLogoUpload: false,
      processingPdfUpload: false,
      processingCustomContent: false,
      editingCustomContent: false,
      logoPlaceholder: process.env.VUE_APP_FILES_PATH + 'logo_placeholder.png',
      pdfPlaceholder: process.env.VUE_APP_FILES_PATH + 'pdf_placeholder.jpg',
      newLogo: null,
      newPdf: null,
      vendor: {
        vendor_id: null,
        active: true,
        short_name: '',
        display_name: '',
        description_1: '',
        description_2: '',
        website: '',
        logo: '',
        pdf: '',
        video: '',
      },
      customContent: {},
      customContentTypeOptions: [
        {"value": "logo", "text": "Logo"},
        {"value": "pdf", "text": "PDF Sell Sheet"},
        {"value": "video", "text": "Video Link"},
        {"value": "website", "text": "Website"},
        {"value": "description_1", "text": "Description #1"},
        {"value": "description_2", "text": "Description #2"},
      ],
      customContentFields: {
        custom_content_id: null,
        category_id: null,
        content_type: null,
        logo: null,
        pdf: null,
        video: null,
        description_1: null,
        description_2: null
      },
      vendorCategories: {},
      allCategories: {},
      validation: {
        display_name: true,
        short_name: true,
        description_1: true,
        description_2: true,
        website: true,
        logo: true,
        pdf: true,
        video: true,
      },
    }    
  },

  methods: {

    getAllCategories() {
      this.$store.commit('generateApiCreds');
      var self = this;

      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'categories', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          self.allCategories = resp.data;
        })
    },

    editCustomContent(index){
      this.editingCustomContent = true;
      this.toggleSnowmanMenu(index);
      this.customContentFields.custom_content_id    = this.customContent[index].custom_content_id;
      this.customContentFields.category_id          = this.customContent[index].category_id;
      this.customContentFields.content_type         = this.customContent[index].content_type;

      if(this.customContent[index].content_type != 'pdf'){
        this.customContentFields[this.customContent[index].content_type] = this.customContent[index].content;
      }

    },

    saveLogo(){
      this.processingLogoUpload = true;
      this.$store.commit("generateApiCreds");

      let formData = new FormData();

      formData.append('upload_type', 'logo_default');
      formData.append('vendor_id', this.vendor.vendor_id ? this.vendor.vendor_id : '');
      formData.append('category_id', this.category_id ? this.category_id : '');
      formData.append('file', this.newLogo);

      const self = this;

      this.$axios
        .post(process.env.VUE_APP_API_PATH + "uploads", formData, {
            auth: {
              username: this.$store.state.auth.apiUsername,
              password: this.$store.state.auth.apiPassword,
            },
          })
        .then(function() {
          self.getVendor();
          self.processingLogoUpload = false;
        })
        .catch(function() {
          self.processingPdfUpload = false;
          // alert('We have an error on our site. Please contact support and let us know. Thanks!')
        });
    },

    savePdf(){
      this.processingPdfUpload = true;
      this.$store.commit("generateApiCreds");

      let formData = new FormData();

      formData.append('user_id', this.$store.state.auth.userId);
      formData.append('token', this.$store.state.auth.token);
      formData.append('upload_type', 'pdf_default');
      formData.append('vendor_id', this.vendor.vendor_id ? this.vendor.vendor_id : '');
      formData.append('category_id', this.category_id ? this.category_id : '');
      formData.append('file', this.newPdf);

      const self = this;

      this.$axios
        .post(process.env.VUE_APP_API_PATH + "uploads", formData, {
            auth: {
              username: this.$store.state.auth.apiUsername,
              password: this.$store.state.auth.apiPassword,
            },
          })
        .then(function() {
          self.getVendor();
          self.processingPdfUpload = false;
        })
    },

    simulatePdfSelectClick(){
      this.$refs.pdfSelector.$el.click();
    },

    simulateLogoSelectClick(){
      this.$refs.logoSelector.$el.click();
    },

    getVendor(){
      this.$store.commit('generateApiCreds');

      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'vendors/id/' + this.$route.params.vendorId, {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.vendor = resp.data;

          if(resp.data.logo){
            this.$set(this.vendor, 'logo', process.env.VUE_APP_FILES_PATH + resp.data.logo);
          }

          if(resp.data.pdf){
            this.$set(this.vendor, 'pdf', process.env.VUE_APP_FILES_PATH + resp.data.pdf);
          }

          this.vendor.active = this.vendor.active == 1 ? true : false;
          this.staticDisplayName = this.vendor.display_name;
          
        })
    },

    getCustomContent() {
      this.$store.commit('generateApiCreds');
      var self = this;

      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'custom_content/vendor_id/' + this.$route.params.vendorId, {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          let customContent = resp.data;

          // We need to set a bunch more stuff before we return custom content...
          for (let [key, value] of Object.entries(resp.data)) {
            customContent[key]['category_display_name'] = self.getCategoryDisplayName(value.category_id);
            customContent[key]['snowmanMenuActive'] = false;
            if(value.content_type == 'pdf'){
              customContent[key]['full_pdf_url'] = process.env.VUE_APP_FILES_PATH + value.content;
            }
            if(value.content_type == 'logo'){
              customContent[key]['full_logo_url'] = process.env.VUE_APP_FILES_PATH + value.content;
            }
          }
          // Finally, we can set custom content
          self.customContent = customContent;
        })
    },

    getCategoryDisplayName(category_id){
      for (let category of Object.values(this.allCategories)) {        
        if(category_id == category.category_id){
          return category.display_name;
        }
      }
      return null;
    },

    getVendorCategories() {
      this.$store.commit('generateApiCreds');
      var self = this;

      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'categories/vendor/' + this.$route.params.vendorId, {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          self.vendorCategories = resp.data;
        })
    },

    saveVendor() {
      this.$store.commit("generateApiCreds");
      const self = this;

      if(!this.validateForm()){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return false;
      }

      this.processingMain = true;

      const formData = new FormData();

      // Is this an existing vendor? If so we need to set our id.
      if(this.vendor.vendor_id){
        formData.append('vendor_id', this.vendor.vendor_id);
      }

      formData.append('user_id', this.$store.state.auth.userId);
      formData.append('token', this.$store.state.auth.token);
      formData.append('active', this.vendor.active);
      formData.append('display_name', this.vendor.display_name);
      formData.append('short_name', this.vendor.short_name);
      formData.append('description_1', this.vendor.description_1);
      formData.append('description_2', this.vendor.description_2);
      formData.append('video', this.vendor.video);
      formData.append('website', this.vendor.website);

      this.$axios
        .post(process.env.VUE_APP_API_PATH + "vendors", formData, {
            auth: {
              username: this.$store.state.auth.apiUsername,
              password: this.$store.state.auth.apiPassword,
            },
          })
        .then(function() {
          self.processing = false;
          self.$router.go(-1);
        })
    },

    saveCustomContent() {

      this.$store.commit("generateApiCreds");
      const self = this;

      // wouldn't hurt to have some validation here...

      this.processingCustomContent = true;
      const formData = new FormData();

      // Is this an existing chunk of custom content? If so we need to set our id.
      if(this.customContentFields.custom_content_id){
        formData.append('custom_content_id', this.customContentFields.custom_content_id);
      }

      formData.append('vendor_id', this.vendor.vendor_id);
      formData.append('content_type', this.customContentFields.content_type);
      formData.append('category_id', this.customContentFields.category_id);

      // This is redundant for file uploading, but I do not care
      formData.append('content', this.customContentFields[this.customContentFields.content_type]);

      if(this.customContentFields.content_type == 'logo'){
        formData.append('upload_type', 'logo_custom');
        formData.append('file', this.customContentFields.logo);
      }

      if(this.customContentFields.content_type == 'pdf'){
        formData.append('upload_type', 'pdf_custom');
        formData.append('file', this.customContentFields.pdf);
      }

      this.$axios
        .post(process.env.VUE_APP_API_PATH + "custom_content", formData, {
            auth: {
              username: this.$store.state.auth.apiUsername,
              password: this.$store.state.auth.apiPassword,
            },
          })
        .then(function() {
          self.getCustomContent();
          self.resetCustomContent();
        })
    },

    deleteCustomContent(index) {

      this.$store.commit("generateApiCreds");
      const self = this;

      this.processingCustomContent = true;

      this.$axios
        .delete(process.env.VUE_APP_API_PATH + "custom_content/" + this.customContent[index].custom_content_id, {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(function() {
          self.getCustomContent();
          self.resetCustomContent();
        })
    },

    toggleSnowmanMenu(index){
      this.customContent[index].snowmanMenuActive = !this.customContent[index].snowmanMenuActive;
    },

    validateForm() {
      this.validation.display_name        = this.vendor.display_name.length > 2 ? true : false;
      this.validation.short_name          = this.vendor.short_name.length > 1 ? true : false;
      this.validation.description_1       = this.vendor.description_1.length > 10 ? true : false;

      // If any values in our validation object are false, then return false
      return Object.values(this.validation).every(Boolean);
    },

    cancel() {
      this.$router.go(-1);
    },

    toggleActive() {
      this.vendor.active = !this.vendor.active;
    },

    openPdf(link) {
      window.open(link);
    },

    newCustomContent(){
      this.editingCustomContent = !this.editingCustomContent;
    },

    resetCustomContent(){
      this.processingCustomContent = false;
      this.editingCustomContent = false;
      for (let key of Object.keys(this.customContentFields)) {
        this.customContentFields[key] = null;
      }
    },
  },

  computed: {
    customContentCategoryOptions(){
      if(this.vendorCategories.length > 1){
        let options = [];
        for (let category of Object.values(this.vendorCategories)) {
          options.push(
            {"value": category.category_id, "text": category.display_name}
          )
        }
        return options;
      } else {
        return [];
      }
    },
  }

}
</script>

<style>

.btn-dash-submit {
  background-color: #007cc0;
  border-color: #007cc0;
}

.btn-dash-submit:hover {
  background-color: rgb(23, 92, 142);
  border-color: rgb(23, 92, 142);
}

.error {
  border: 2px solid #d9534f;
}

.btn.disabled {
  cursor:not-allowed;
}

.custom-file-label {
  border: 1px solid #dee2e6 !important;
}

.plus-icon {
  height: 40px;
  width: 40px;
  background-color: rgb(245, 247, 250);
  color: #212529;
  border-radius: 25px;
  float: right;
}

.plus-icon:hover {
  background-color: rgb(23, 92, 142);
  cursor: pointer;
  color: white;
}

.widget-item {
  text-decoration: none;
}

.widget-item:hover {
  background-color: grey;
}

.table > tbody > tr:first-child > td {
    border: none;
}

.btn-dashboard {
  color: #6c757d;
  padding: 5px;
}

.btn-dashboard:hover {
  color: white;
  border-radius: 5px;
  background-color: rgb(23, 92, 142);
  text-decoration: none;
}

.snowman-container:hover {
  cursor: pointer;
  background-color: rgb(23, 92, 142);
}

.snowman-container:hover .snowman {
  color: white;
}

.snowman {
  color: #6c757d;
}

.snowman-flyout-menu a {
  color: rgb(73, 80, 87);
  padding: 2px;
}

.snowman-flyout-menu a:hover {
  color: #007bff;
  text-decoration: none;
}

.content {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 40px;
  }

}

</style>
